<template>
  <div class="flex flex-center">
    <h3>Loading...</h3>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  async created() {
    await this.$store.dispatch('clear');
    await this.$auth.logout();
  },
};
</script>
